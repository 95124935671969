import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { Location } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { JwtUtil } from './jwtUtils';

@Injectable({ providedIn: 'root' })
export class ApiUtil {
    constructor(
        private http: HttpClient,
        private router: Router,
        private jwtUtil:JwtUtil,
     ) {}

    doGet(url:string,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        params?: HttpParams | {
            [param: string]: string | string[];
        };
    }):Observable<any>{
            return this.http.get(url,options).pipe(       
                (res)=> {
                   return res;
                },
                catchError(this.handleError)
         );
    }

    doPost(url:string,body:any| null,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        // observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
    }):Observable<any>{
       return this.http.post(url,body,options).pipe(        
        map(function (res) {
           return res;
        }),
        catchError(this.handleError)
       );
    }

    doPut(url:string,body:any| null,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        params?: HttpParams | {
            [param: string]: string | string[];
        };
    }):Observable<any>{
         console.log(options);
         return this.http.put(url,body,options).pipe(        
            map(function (res) {
               return res;
            }),
            catchError(this.handleError)
           );
    }

    doDelete(url:string,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        // observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
    }):Observable<any>{
         return this.http.delete(url,options).pipe(        
            map(function (res) {
               return res;
            }),
            catchError(this.handleError)
           );
    }

    refresh(curl:string):Observable<any>{
        console.log(curl);
        const httpOptions = {
            headers: new HttpHeaders({
             'Accept': 'application/json',
             'accessToken':this.jwtUtil.getAccessToken(),
             'refreshToken':this.jwtUtil.getRefreshTocken(),
           })
          };
        return this.http.get(curl+"/token/refresh",httpOptions).pipe(
            (res)=> {
                console.log(res);
                return res;
             },
             catchError((err: HttpErrorResponse) =>{ 
                 console.log(err)
                 return throwError(err);
             })
        );
    }

    private handleError(error) {
        //: HttpErrorResponse
        console.log(error);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if(error&&error.status&&error.erro){
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          }
        }
        // return an observable with a user-facing error message
        return throwError(
          error);
      };
}