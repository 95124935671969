// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    logging : false,
    env:'microsite_prod',
    apiUrl:"https://coupon-backend.hkt.com",
    headerMode:true,
    sessionTimeOutMinute:30,
    pageLimit:5, 
    couponPageLimit:5,
    searchKeyShowSize:10,
    searchKeySaveSize:30,
    micrositeUrl:"https://prod-coupon-microsite-frontend.herokuapp.com",
    threePartRelease:0,
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
