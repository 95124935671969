import { CustomerModel } from "./customer.model";
import { CouponItemModel } from "./couponItem.model";
import { ProviderCouponModel } from "./providerCoupon.model"

export class CouponInstanceModel{
    id?:string;
    couponAlias?:string;
    couponCode?:string;
    validFrom?:string;
    validTo?:string;
    status?:string;
    remainingUse?:number;
    remainingBalance?:number;
    referralCount?:number;
    refereeCouponCode?:string;
    referrerCouponCode?:string;
    createdDate?:string;
    customer?:CustomerModel;
    remark?:string;
    remarkCht?:string;
    couponItem?:CouponItemModel;
    providerCoupon?:ProviderCouponModel;
    shareContent?:string;
    source?:string;
    cashDiscountType?:string;
    statusLabel?:string;
    initBalance?:number;
    selected?:boolean;//page use
    dateOff?:number;//page use
    banlanceShowLabel?:string;//page use
    banlanceShowValue?:string;//page use
    is3rdCoupon?:boolean;//page use
    isShowToRedeem?:boolean;//page use
    isFavorites?:boolean;//page use
}