import { Component, OnInit,OnDestroy, ErrorHandler,Output,Input,EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { JwtService } from '../../../data/service/jwt.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { CustomerInfoModel } from '../../../data/models/customerInfo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { LoginModel } from '../../../data/models/login.model';
// import { InfiniteScroll } from 'angular2-infinite-scroll';
import { ErrHandler } from '../../../data/util/errHandler';
import { ComponentUtil } from '../../../data/util/componentUtil';
import { CouponListCommonUtil } from '../../../data/util/couponListCommonUtil';
import { FavroitesService } from '../../../data/service/favroites.service';
import { environment } from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-myewallet-rewards',
    // directives: [ InfiniteScroll ], 
    templateUrl: './myewallet-rewards.component.html',
    styleUrls: ['./myewallet-rewards.component.scss']
})
export class MyEwalletRewardsComponent implements OnInit{
    /**api use data */
    couponList:CouponInstanceModel[]=[];//page use result list
    allCouponList:CouponInstanceModel[]=[];//api return result list
    resultBrandMap:Map<string,number>=new Map<string,number>();//api return get the exist brand

    /** filter variable */
    // filterBrand:string;
    filterStatus:string;
    // filterType:string;
    // filterCouponName:string;

    /** touch event variable */
    touchStartY:number;
    touchEndY:number;

    /** component event variable */
    @Output()
    qrCode= new EventEmitter<any>();

    @Output()
    share=new EventEmitter<any>();

    @Output()
    popDetail=new EventEmitter<any>();

    @Input() activeTab: string;

    /** page flag  */
    showCouponList:boolean=false;//not data show flag
    channel:string;
    appIssueBrand:string;
    favoritesString:string;

    /** storage use key*/
    private brandFilterKey:string="myWallet_rewards_brands_filter";
    private typeFilterKey:string="myWallet_rewards_type_filter";
    private statusFilterKey:string="myWallet_rewards_status_filter";
    private nameFilterKey:string="myWallet_rewards_name_filter";

    /**split page pram */
    defaultLimit:number=environment.couponPageLimit;
    nextOffset:number=0;
    totalNumber:number=0;
    currentY:number=0;
    dateFormat:string;
    isWeb:boolean;
    scrollLock:boolean;
    env=environment;

    constructor(private router: Router,private ewalletListService:EwalletListService,private jwtService:JwtService
      ,private location: Location,private errMsgUtil:ErrMsgUtil,private jwtUtil:JwtUtil,private popupMsgUtil:PopupMsgUtil
      ,private favroitesService:FavroitesService,private componentUtil:ComponentUtil,private translateService:TranslateService,){}


    ngOnInit() { 
      this.channel=this.jwtUtil.getSubServiceAppType();
      this.appIssueBrand=this.jwtUtil.getIssueBrandFromServiceAppType();
      var sv=document.getElementsByClassName('rewardsScrollFreshView')[0];
      sv.addEventListener("touchstart",this.onTouchStart,false);
      sv.addEventListener("touchend",this.onTouchEnd,false);
      this.favoritesString ='';

    }

    init(){
      // var sbf=sessionStorage.getItem(this.brandFilterKey);
      // var stf=sessionStorage.getItem(this.typeFilterKey);
      var ssf=sessionStorage.getItem(this.statusFilterKey);
      // var snf=sessionStorage.getItem(this.nameFilterKey);
       this.translateService.get("momentDateFormat").subscribe(
            (res)=>{
                this.dateFormat=res;
            }
        );
      if(!ComponentUtil.checkSelectNotNull(ssf)){
        sessionStorage.setItem(this.statusFilterKey,"REDEEMABLE");
        ssf="REDEEMABLE";
     }
     console.log("session brand:"+null+";type:"+null+";status:"+ssf+";name:"+null);
    //  this.filterBrand=sbf;
     // this.filterType=stf;
     this.filterStatus=ssf;
     this.scrollLock=false;
     // this.filterCouponName=null;
     this.isWeb=localStorage.getItem('env')?localStorage.getItem('env')=='web':false;
     const _this=this;
     this.initDetail();
    }

    initDetail(){
      this.resultBrandMap.clear();
      this.allCouponList=[];
      this.couponList=[];
      this.showCouponList=false;
      this.searchCouponList(0,this.defaultLimit,true,this.filterStatus,true,false);
    }

    goDetail(id:string){
      var a={'couponId':id,'page':'rewards'}
      this.popDetail.emit(a);
    }

    /* component method  start */
    //1 search bar
    searchCouponForCouponName(){
      // // var sbf=sessionStorage.getItem("myWallet_brand_filter");
      // // var stf=sessionStorage.getItem("myWallet_type_filter");
      // var ssf=sessionStorage.getItem("myWallet_rewards_status_filter");
      // // var snf=sessionStorage.getItem("myWallet_name_filter");
      // // this.filterBrand=sbf;
      // // this.filterType=stf;
      // this.filterStatus=ssf;
      // // this.filterCouponName=snf;
      // var sc=document.getElementById('searchCoupon');
      // console.log(sc);
      // this.filterCouponName=sc.getAttribute('ng-reflect-model');
      // if(!this.filterCouponName||this.filterCouponName==''){
      //    this.clickCouponStatus('All');
      //    sessionStorage.removeItem("myWallet_rewards_status_filter");
      // } 
      // // this.filterResult(this.filterBrand,this.filterStatus,this.filterType,this.filterCouponName);
      // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,this.filterStatus,null,this.filterCouponName);
  }

  //2 brand filter
  // brandChange(event){
  //   this.filterBrand=event;
  //   this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,null);
  //   this.jwtUtil.setupSessionTime();
  //   sessionStorage.setItem(this.brandFilterKey,this.filterBrand);
  // }

  //3 status filter
  statusChange(event){
    var status:string=event;
        this.filterStatus=status;
        sessionStorage.setItem(this.statusFilterKey,status);  
        this.nextOffset=0;        
        this.searchCouponList(this.nextOffset,this.defaultLimit,true,status,true,false); 
        // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,this.filterBrand,this.filterStatus,null,null);
        this.jwtUtil.setupSessionTime();
  }
    
  //4 touch event
  onTouchStart(e:TouchEvent){
    this.touchStartY=e.touches[0].clientY;
  }

  onTouchEnd(e:TouchEvent){
    this.touchEndY=e.changedTouches[0].clientY;

    var toButtom=document.body.clientHeight-window.screen.availHeight-114;//114 is top of header
    if(window.scrollY>toButtom){//to the buttom flag
       if(this.touchStartY>this.touchEndY+60){
           console.log('more coupon');
           document.getElementById('myewallet-rewards_nextPage').click();
       }
    }

    if(window.scrollY==0){
      if(this.touchEndY>this.touchStartY+60){
        document.getElementById('myewallet-rewards').click();
        console.log("init detail");
      }
    }
  }

   //5 name card event +
    clickQrCode(){
        this.qrCode.emit();
    }

    clickShare(){
      console.log(1);
      this.share.emit();
    }

    clickRedemptionRecords(){
      document.getElementById('myewalletrewards-redemptionRecords').click();
    }
    //5 name card event -

    //6 favorite 
    getFavorites(){ //by coco:get favorites coupon from backend
      this.favroitesService.getFavoritesList('1234').subscribe(
        (res)=>{
          res=this.jwtUtil.setupResponse(res);
          console.log('favorites:'+ JSON.stringify(res.favourites));
          this.favoritesString = JSON.stringify(res.favourites);
          this.favoritesString = this.favoritesString.replace(/"/g,'');
          this.favoritesString = this.favoritesString.replace('null','');
          if(this.couponList){
            this.couponList=CouponListCommonUtil.filterResultWithFavriotes(this.couponList,this.filterStatus,this.favoritesString);
          }
        }
        ,(err)=>{
             this.popupMsgUtil.endLoading();
             const _this=this;
             function getHandler():ErrHandler{
                let handler=<ErrHandler><unknown>function () { };
                handler.handleErr=function(){
  
                }
                handler.handleRefreshToken=function(){
                  _this.getFavorites();             
                }
                return handler;
             }
             var eh:ErrHandler=getHandler();
             this.errMsgUtil.handleErr(eh,err.error);            
           }
        );
    }

    fromChildFunc(data) {
      console.log('on click data:'+data);
      this.favoritesString = data;
      // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,this.filterStatus,null,null);
      // if(this.couponList){
      //   this.couponList=CouponListCommonUtil.filterResultWithFavriotes(this.couponList,this.filterStatus,this.favoritesString);
      // }
    }
   

    /** split page search method */
    searchCouponList(offset:number,limit:number,reset:boolean,status:string,hasPromotional:boolean,hasSpringboard:boolean){  
          if(reset){
            if(status){
            }
            else{
               this.resultBrandMap.clear();
            }
            this.allCouponList=[];
            this.couponList=[];
          }
          this.popupMsgUtil.loading();
          this.ewalletListService.getCouponList('R',offset,this.defaultLimit,"ALL",status,hasPromotional,hasSpringboard).subscribe(
            (res)=>{
              console.log(res);
              this.getFavorites();//get favorites,after getting the favortiesString,update the couponList in the succeed return;
              res=this.jwtUtil.setupResponse(res);
              this.totalNumber=res.totalNumber;
              console.log(this.totalNumber);
              res=res.data;
              res=CouponListCommonUtil.setDayOffAndSort(res,this.resultBrandMap,this.dateFormat);
              this.nextOffset=offset+limit;
              if(reset){
                this.allCouponList=[];
                this.allCouponList=res;
              }else
                this.allCouponList=this.allCouponList.concat(res);
              console.log(this.allCouponList);
              // this.couponList=CouponListCommonUtil.filterResult(this.allCouponList,null,this.filterStatus,null,null);
              this.couponList = this.allCouponList;
              console.log(this.couponList);
              this.showCouponList=true;
              if(this.filterStatus=='PENDING'||
                this.filterStatus=='REDEEMED'||
                this.filterStatus=='EXPIRED' 
              ){
                  var csDiv=document.getElementById('rewardsStatusDiv');
                  csDiv.scroll(1000,0);
              }
              if(this.currentY>0){
                window.scroll(0,this.currentY);
                this.currentY=0;
              }
              this.popupMsgUtil.endLoading();
            }
            ,(err)=>{
                this.showCouponList=true;
                this.popupMsgUtil.endLoading();
                const _this=this;
                function getHandler():ErrHandler{
                    let handler=<ErrHandler><unknown>function () { };
                    handler.handleErr=function(){

                    }
                    handler.handleRefreshToken=function(){
                      _this.initDetail();              
                    }
                    return handler;
                }
                var eh:ErrHandler=getHandler();
                this.errMsgUtil.handleErr(eh,err.error);            
              }
            );
      }

      moreCoupon(){
        var status=sessionStorage.getItem(this.statusFilterKey);
        if(this.allCouponList.length>0&&this.totalNumber>this.nextOffset){
            this.currentY=window.scrollY;
            this.searchCouponList(this.nextOffset,this.defaultLimit,false,status,false,false);
        }else{
          this.scrollLock=false;
        }
      }
      /** split page search method */


      onScrollDown() {
        if(this.isWeb){
          console.log('rewards scrolled down++!!');
          var toButtom=document.body.clientHeight-window.screen.availHeight-154;//114 is top of header
          if(window.scrollY>toButtom){
            if(!this.scrollLock){
              this.scrollLock=true;
             document.getElementById('myewallet-rewards_nextPage').click();
            }
          }
          console.log('scrolled down--!!');
        }
    }
}