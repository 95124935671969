<button id="myewallet-mgm" (click)="init()" style="display:none;"></button>
<button id="myewallet-mgm_search" (click)="searchCouponForCouponName()" style="display:none;"></button>
<button id="myewallet-mgm_nextPage" (click)="moreCoupon()" style="display:none;"></button>
<div class="container-fluid mgmScrollFreshView " style="padding-top:{{env.threePartRelease==1?(isWeb?114:0):114}}px; overflow:hidden;">
    <div *ngIf="activeTab=='mgm-tab' || activeTab=='myewallet-mgm'" class="row" infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()">
        <div *ngIf="channel!='MyHKT'" class="col-12 col-md-6 col-lg-4 col-xl-3" style="min-width: 320px; padding-top:10px;padding-right:30px;padding-left:15px;" >
            <app-myewallet-nameCard (qrCode)="clickQrCode()" (share)="clickShare()" [tab]="'mgm'"></app-myewallet-nameCard>
        </div>  
        <div *ngIf="channel=='MyHKT'" class="col-12 col-md-12 col-lg-12 col-xl-12" style="min-width: 320px; padding-top:10px;padding-right:30px;padding-left:15px;" >
            <app-myewallet-nameCard (qrCode)="clickQrCode()" (share)="clickShare()" [tab]="'mgm'"></app-myewallet-nameCard>
        </div>  
        <div class="col-12 col-md-12 col-lg-12 col-xl-12" style="padding-right: 35px;">
            <app-myewallet-brandfilter [issueBrandList]="issueBrandList" [resultBrandMap]="resultBrandMap" [filterBrand]="filterBrand" (brandChange)="brandChange($event)" ></app-myewallet-brandfilter>
        <div class="secondly_header" *ngIf="resultBrandMap&&resultBrandMap.size>0&&(!(resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand)))" style="position:absolute;overflow:auto;width:92%;top:{{resultBrandMap.size==1&&resultBrandMap.get(appIssueBrand)?20:75}}px;height:1px;background-color:var(--splitColor);"></div>
            <app-myewallet-couponstatusfilter [page]="'mgm'" [filterStatus]="filterStatus" (statusChange)="statusChange($event)"></app-myewallet-couponstatusfilter>
        </div>
         
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;" *ngFor="let cp of couponList">
            <div  class="walletCard" style="padding-left:0px;padding-right:0px;vertical-align: middle;border-radius:15px;border:1px solid #B5B2B2;" >
                <app-myewallet-couponCard  [cp]='cp' [favoritesString] ='favoritesString' (favoritesStringChanged)="fromChildFunc($event)" (openDetail)="goDetail(cp)" (share)="clickShare()" [tab]="'mgm'" (refreshList)="init()"></app-myewallet-couponCard>
            </div> 
        </div>    
        <!-- <p *ngIf="(showCouponList&&couponList&&(nextOffset>=totalNumber))||((channel=='csl'||channel=='1010')&&couponList==undefined&&!(filterStatus=='All'||filterStatus=='REDEEMABLE'))" class="noMoreCoupon">{{'noMoreCoupon'|translate}}</p>
        <p *ngIf="channel=='1010'&&couponList==undefined&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_1010'|translate}}</p>
        <p *ngIf="channel=='csl'&&couponList==undefined&&(filterStatus=='All'||filterStatus=='REDEEMABLE')" class="noMoreCoupon">{{'noMoreCoupon_csl'|translate}}</p> -->

        <p *ngIf="showCouponList" class="noMoreCoupon">
            {{((channel == '1010' && (couponList == undefined?true:couponList.length == 0) && (filterStatus == 'All' || filterStatus == 'REDEEMABLE')) ?
             'noMoreCoupon_1010' : 
             ((channel == 'csl' && (couponList == undefined?true:couponList.length == 0) && (filterStatus == 'All' || filterStatus == 'REDEEMABLE')) ? 
             'noMoreCoupon_csl' : 
             (channel == 'MyHKT' && (couponList == undefined?true:couponList.length == 0))?
             'noMoreCoupon_MyHKT':
             'noMoreCoupon')) | translate}}</p>

    </div> 
    <!-- <div class="row"> -->
        <!-- <p class="col-12" style="text-align: center;margin-top:10px;" *ngIf="isWeb&&allCouponList&&allCouponList.length>0&&totalNumber>(nextOffset)" (click)="moreCoupon()">{{'more'|translate}}▼</p> -->
        <!--&&totalNumber>(nextOffset+defaultLimit)-->
     <!-- </div> -->
</div>


<button id="mgmRewardHistroyModalBtn" style="display: none;" data-toggle="modal" data-target="#mgmRewardHistoryModal" (click)="initMgmRewardHist()"></button>
<div class="modal fade secondly_header" id="mgmRewardHistoryModal" style="padding-right:0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;">
    <div class="secondly_header modal-dialog modal-lg " style="max-width:100%;margin:0px;">
          <div class="secondly_header modal-content" style="padding-left:0px;padding-right:0px;border:none;" >
                <div class="secondly_header modal-body  container-fluid" id="couponDetailModalBody">
                      <!--<i id="nameCardQrClose" class="close popClose" data-dismiss="modal" >×</i>--->
                      <img id="mgmRewardHistoryPop" class="close popClose" data-dismiss="modal" src="/assets/common/image/icons/{{channel}}/icon_close.png" width="30" height="30"/>
                      <app-myewallet-mgmRewardHistory></app-myewallet-mgmRewardHistory>
                </div>
          </div>
    </div>
</div>

