import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { EwalletListService } from '../../../data/service/ewalletList.service';
import { CouponInstanceModel } from '../../../data/models/couponInstance.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrMsgUtil } from '../../../data/util/errMsgUtil';
import { PopupMsgUtil } from '../../../data/util/popupMsgUtil';
import { JwtUtil } from '../../../data/util/jwtUtils';
import { LoginModel } from '../../../data/models/login.model';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { JwtService } from '../../../data/service/jwt.service';
import { environment } from 'src/environments/environment';
import { JqueryUiUtils } from 'src/assets/common/util/jquiutil';
import { ComponentUtil } from 'src/app/data/util/componentUtil';
import { CouponListCommonUtil } from 'src/app/data/util/couponListCommonUtil';
import { ErrHandler } from '../../../data/util/errHandler';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
  selector: 'app-myewallet-detail',
  templateUrl: './myewallet-detail.component.html',
  styleUrls: ['./myewallet-detail.component.scss']
})
export class MyEwalletDetailComponent implements OnInit {

  constructor(private router: Router, private ewalletListService: EwalletListService
    , private location: Location, private errMsgUtil: ErrMsgUtil, private jwtUtil: JwtUtil
    , private popupMsgUtil: PopupMsgUtil, public JwtService: JwtService,
    private translateService: TranslateService, private http: HttpClient,) { }


  tab: string;//three tab coupon/rewards/mgm
  filterCouponName: string;//search bar value
  nameCardQrCode: string;//name card qr value 
  selectCoupon: CouponInstanceModel = null; //popup coupon detail value
  selectSbCoupon: CouponInstanceModel = null; //popup SpringBoard coupon detail value
  channel: string;//
  searchKeys: string[] = [];//use for page key list
  searchKeySet: Set<string> = new Set<string>();//the set of key
  searchKeyStorgeKey = 'couponSearchKeyList';
  openDetailPage: string = '';
  private nameFilterKey: string = "myWallet_search_name_filter";
  env = environment;
  dateFormat: string;
  activeTab: string;

  ngOnInit() {
    let initTab = sessionStorage.getItem('tab');
    if(initTab=='coupons-tab' || initTab=='rewards-tab' || initTab=='mgm-tab') {
      this.changeMenu(initTab);
    }else{
      this.changeMenu('coupons-tab');
    }
    this.channel = this.jwtUtil.getSubServiceAppType();
    // this.filterCouponName='';

    var saveSearchKeyList = localStorage.getItem(this.searchKeyStorgeKey);
    if (saveSearchKeyList) {
      var keyList: string[] = JSON.parse(saveSearchKeyList);
      for (var i = 0; i < keyList.length; i++) {
        this.searchKeySet.add(keyList[i]);
      }
    }
    this.updateSearchKeyList(false);
    console.log(this.searchKeys);
    JqueryUiUtils.loadTextVal('searchCoupon', this.searchKeys);
    this.translateService.get("momentDateFormat").subscribe(
      (res) => {
        this.dateFormat = res;
      }
    );  
  }

  changeMenu(menu: string) {
    this.activeTab = menu;
    var searchCoupon = document.getElementById('searchCoupon');
    this.filterCouponName = '';
    sessionStorage.setItem("myWallet_coupon_brands_filter","ALL");
    sessionStorage.setItem("myWallet_rewards_brands_filter","ALL");
    sessionStorage.setItem("myWallet_mgm_brands_filter","ALL");
    sessionStorage.setItem("myWallet_coupon_status_filter","REDEEMABLE");
    sessionStorage.setItem("myWallet_rewards_status_filter","REDEEMABLE");
    sessionStorage.setItem("myWallet_mgm_status_filter","REDEEMABLE");
    searchCoupon.setAttribute('ng-reflect-model', this.filterCouponName);
    JqueryUiUtils.setInputValue('searchCoupon', '');
    this.tab = menu;
    var btn = document.getElementById(menu);
    btn.click();
  }

  clickSearch() {
    console.log('angular:' + this.filterCouponName);
    console.log('js:' + JqueryUiUtils.getInputValue('searchCoupon'));
    var searchCoupon = document.getElementById('searchCoupon');
    var jsGetVal = JqueryUiUtils.getInputValue('searchCoupon');
    jsGetVal = jsGetVal ? jsGetVal.trim() : jsGetVal;
    this.filterCouponName = jsGetVal;
    searchCoupon.setAttribute('ng-reflect-model', this.filterCouponName);
    // var btn=document.getElementById(this.tab+'_search');
    // btn.click();
    if (this.filterCouponName && this.filterCouponName != '') {
      this.searchKeySet.delete(this.filterCouponName);
      this.searchKeySet.add(this.filterCouponName);
      this.updateSearchKeyList(true);
      console.log(this.searchKeys);
      sessionStorage.setItem(this.nameFilterKey, this.filterCouponName);
      var redirectSearch = document.getElementById('redirectSearchCoupon');
      redirectSearch.click();
    }
    // console.log();
    // this.router.navigateByUrl('/searchCoupon');
    // this.location=this.location;
    // this.location.go('/searchCoupn');
    // this.location.forward();

    // var btn=document.getElementById('openSearchBtn');
    // btn.click();
    // var mv=document.getElementById('mySearchModal');
    // mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;');
    // var init=document.getElementById('myewallet-search'); 
    // init.click();


  }

  private updateSearchKeyList(save: boolean) {
    this.searchKeys = ComponentUtil.convertSetToArray(this.searchKeySet);
    this.searchKeys = ComponentUtil.getQueueByArray(this.searchKeys, environment.searchKeySaveSize);
    if (save) {
      // var saveKeys=ComponentUtil.getQueueByArray(this.searchKeys,environment.searchKeySaveSize);
      var saveStr = JSON.stringify(this.searchKeys);
      localStorage.setItem(this.searchKeyStorgeKey, saveStr);
    }
    // this.searchKeys=ComponentUtil.getQueueByArray(this.searchKeys,environment.searchKeySaveSize);
    this.searchKeys = this.searchKeys.reverse();
    if (save) {
      JqueryUiUtils.loadTextVal('searchCoupon', this.searchKeys);
    }
  }

  openNameCardQrCode() {
    this.nameCardQrCode = document.getElementById('NameCard_Qrcode').innerHTML;
    var btn = document.getElementById('myNameCardQrModalBtn');
    btn.click();
  }

  openCouponDetail(detail) {
    console.log("view detail id is " + detail.couponId);
    console.log(detail);
    this.openDetailPage = detail.page;
    this.popupMsgUtil.loading();
    const _this = this;
    if (detail.couponId) {
      this.ewalletListService.getCouponDetail(detail.couponId).subscribe(
        (res) => {
          console.log(res);
          res = this.jwtUtil.setupResponse(res);
          this.selectCoupon = res;
          // this.selectCoupon=CouponListCommonUtil.changeCouponDateFormat(this.selectCoupon,this.dateFormat);
          if(this.selectCoupon.validFrom) {
            this.selectCoupon.validFrom = dayjs.utc(this.selectCoupon.validFrom).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
          }
          if(this.selectCoupon.validTo) {
            this.selectCoupon.validTo = dayjs.utc(this.selectCoupon.validTo).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');            
          }
          this.selectCoupon = CouponListCommonUtil.changeCouponBanlanceShow(this.selectCoupon, false);
          this.popupMsgUtil.endLoading();
          var btn = document.getElementById('openCouponDetailBtn');
          btn.click();
          var mv = document.getElementById('myCouponDetailModal');
          mv.setAttribute('style', 'display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;');
          var initCouponCode = document.getElementById(this.openDetailPage + 'couponDetailInitCouponCode');
          initCouponCode.innerHTML = this.selectCoupon.couponCode;
          var init = document.getElementById(this.openDetailPage + 'couponDetailInit');
          init.click();
        },
        (err) => {
          this.popupMsgUtil.endLoading();
          // mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:30%;height:70%;overflow: hidden;overflow-y:scroll;');
          function getHandler(): ErrHandler {
            let handler = <ErrHandler><unknown>function () { };
            handler.handleErr = function () {

            }
            handler.handleRefreshToken = function () {
              _this.openCouponDetail(detail);
            }
            return handler;
          }
          var eh: ErrHandler = getHandler();
          this.errMsgUtil.handleErr(eh, err.error);
        }
      );
    }else if(detail.couponItemCode){
      this.ewalletListService.getCouponItemDetail(detail.couponItemId).subscribe(
        (res) => {
          console.log(res);
          res = this.jwtUtil.setupResponse(res);
          this.selectCoupon = new CouponInstanceModel();
          this.selectCoupon.couponItem = res;
          // this.selectCoupon=CouponListCommonUtil.changeCouponDateFormat(this.selectCoupon,this.dateFormat);
          if(this.selectCoupon.validFrom) {
            this.selectCoupon.validFrom = dayjs.utc(this.selectCoupon.validFrom).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
          }
          if(this.selectCoupon.validTo) {
            this.selectCoupon.validTo = dayjs.utc(this.selectCoupon.validTo).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss');            
          }
          this.selectCoupon = CouponListCommonUtil.changeCouponBanlanceShow(this.selectCoupon, false);
          this.popupMsgUtil.endLoading();
          var btn = document.getElementById('openCouponDetailBtn');
          btn.click();
          var mv = document.getElementById('myCouponDetailModal');
          mv.setAttribute('style', 'display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;');
          var initCouponCode = document.getElementById(this.openDetailPage + 'couponDetailInitCouponCode');
          initCouponCode.innerHTML = this.selectCoupon.couponCode;
          var init = document.getElementById(this.openDetailPage + 'couponDetailInit');
          init.click();
        },
        (err) => {
          this.popupMsgUtil.endLoading();
          // mv.setAttribute('style','display: block; padding-right:0px;padding-left: 0px;position:flex;top:30%;height:70%;overflow: hidden;overflow-y:scroll;');
          function getHandler(): ErrHandler {
            let handler = <ErrHandler><unknown>function () { };
            handler.handleErr = function () {

            }
            handler.handleRefreshToken = function () {
              _this.openCouponDetail(detail);
            }
            return handler;
          }
          var eh: ErrHandler = getHandler();
          this.errMsgUtil.handleErr(eh, err.error);
        }
      )
    }else{
      this.selectCoupon=null;
      this.selectSbCoupon = detail.coupon;
      this.popupMsgUtil.endLoading();
      var btn = document.getElementById('openCouponDetailBtn');
      btn.click();
      var mv = document.getElementById('myCouponDetailModal');
      mv.setAttribute('style', 'display: block; padding-right:0px;padding-left: 0px;position:flex;top:0%;height:100%;overflow: hidden;overflow-y:scroll;');
      setTimeout(() => {
        var init = document.getElementById(this.openDetailPage + 'couponDetailInit');
        init.click(); 
      }, 0);    
    }



    //  this.selectCoupon=couponDetail;
  }

  openShare() {
    // var btn=document.getElementById('shareModalBtn');
    // btn.click(); 
    this.translateService.get("msg.copySuccessMsg").subscribe(
      (res) => {
        console.log(res);
        this.popupMsgUtil.toast(res, 2000);
      }
    );
  }

  clickCopyShare() {
    var shareContent = document.getElementById('shareModalContent');
    const range = document.createRange();
    range.selectNode(shareContent);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.translateService.get("msg.copySuccessMsg").subscribe(
      (res) => {
        console.log(res);
        this.popupMsgUtil.toast(res, 2000);
      }
    );
  }
}